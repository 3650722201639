import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import Play from '../../icons/play.svg'
import './dynamicPlayer.scss'

export default function DynamicPlayer({ large = false, preInitialize = false, onLoad, onPlay, url, onPlayerClick, ...otherProps }) {

  const playerRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isInitialized, setIsInitialized] = useState(preInitialize)

  useEffect(() => {
    if(playerRef.current && onLoad) {
      onLoad(playerRef)
    }
  }, [isInitialized])

  const getClassName = () => {
    let className = 'dynamic-player'
    if (large) {
      className += ' dynamic-player--large'
    }
    return className
  }

  const handlePlay = () => {
    setIsPlaying(true)
    onPlay && onPlay(playerRef)
    onPlayerClick && onPlayerClick()
  }

  const handleInitialize = () => {
    setIsInitialized(true)
    setIsPlaying(true)
    onPlayerClick && onPlayerClick()
  }

  const renderPlayer = () => {
    return (
      <React.Fragment>
        {isInitialized &&
          <ReactPlayer
            ref={playerRef}
            url={url}
            playing={isPlaying}
            controls
            onStart={() => setIsPlaying(true)}
            onPlay={handlePlay}
            onPause={() => setIsPlaying(false)}
            className="dynamic-player__aspect-ratio-box__player"
            width="100%"
            height="100%"
            {...otherProps}
          />
        }
        <button onClick={handleInitialize} className="dynamic-player__aspect-ratio-box__placeholder">
          <img src={Play} alt="play video" />
          <div>Listen</div>
        </button>
      </React.Fragment>
    )
  }

  return (
    <div className={getClassName()}>
      <div className="dynamic-player__aspect-ratio-box">
        {renderPlayer()}
      </div>
    </div>
  )
}
