import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './background.scss'

export default function Background({ activeImage }) {
  return (
    <div className="background">
      <AnimatePresence>
        <motion.img
          src={activeImage}
          key={activeImage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0.5 } }}
        />
      </AnimatePresence>
    </div>
  )
};
