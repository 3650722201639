import React, { useState } from 'react'
import Game from './Game'
import './games.scss'

export default function Games({ gamesData, onSetActiveBackgroundImage, handlePlayerLoad, handlePlayerPlay }) {

  return (
    <div className="games">
      {gamesData.map(({ node }) => 
        <Game
          key={node.id}
          data={node.frontmatter}
          onPlayerClick={onSetActiveBackgroundImage}
          onPlayerLoad={handlePlayerLoad}
          onPlayerPlay={handlePlayerPlay}
        />
      )}
    </div>
  )
}
