import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/Hero'
import Games from '../components/Games'
import './index.scss'
import { Helmet } from "react-helmet"
import Background from '../components/Background'

const IndexPage = ({ data }) => {
  const edges = data.allMarkdownRemark.edges || []
  const gamesData =
    edges.filter(({ node }) => node.frontmatter.title && node.frontmatter.year)
      .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
  const heroData = edges.find(({ node }) => node.frontmatter.demourl)
  const aboutData = edges.find(({ node }) => node.frontmatter.content)
  const contactData = edges.find(({ node }) => node.frontmatter.links)
  const [activeBackgrounImage, setActiveBackgroundImage] = useState(
    gamesData[0].node.frontmatter.backgroundimage ||
    'https://images.unsplash.com/photo-1507838153414-b4b713384a76?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
  )

  const [playerRefs, setPlayerRefs] = useState([])

  const handlePlayerLoad = (ref) => {
    playerRefs.forEach(playerRef => {
      const internalPlayer = playerRef.current.getInternalPlayer()
       // youtube
       if (internalPlayer.pauseVideo) {
        internalPlayer.pauseVideo()
      // vimeo
      } else if (internalPlayer.pause) {
        internalPlayer.pause()
      }
    })
    setPlayerRefs([...playerRefs, ref])
  }

  const handlePlayerPlay = (ref) => {
    playerRefs.forEach(playerRef => {
      if (playerRef.current !== ref.current) {
        const internalPlayer = playerRef.current.getInternalPlayer()
        // youtube
        if (internalPlayer.pauseVideo) {
          internalPlayer.pauseVideo()
        // vimeo
        } else if (internalPlayer.pause) {
          internalPlayer.pause()
        }
      }
    })
  }

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mark Sparling Music</title>
        <link rel="canonical" href="https://marksparling.ca/" />
      </Helmet>
      <Hero
        data={heroData.node.frontmatter}
        aboutData={aboutData}
        contactData={contactData}
        onPlayerLoad={handlePlayerLoad}
        onPlayerPlay={handlePlayerPlay}
      />
      <Games
        onSetActiveBackgroundImage={setActiveBackgroundImage}
        gamesData={gamesData}
        handlePlayerLoad={handlePlayerLoad}
        handlePlayerPlay={handlePlayerPlay}
      />
      <Background activeImage={activeBackgrounImage} />
    </main>
  )
}

export default IndexPage

export const pageQuery = graphql`
query {
  allMarkdownRemark {
    edges {
      node {
        id
        frontmatter {
          demourl
          title
          year
          link
          copyright
          embed
          order
          backgroundimage
          role
          content
          links {
            label
            link
          }
        }
      }
    }
  }
}
`
