import React, { useState } from 'react'
import DynamicPlayer from './common/DynamicPlayer'
import Modal from 'react-modal'
import './hero.scss'

Modal.setAppElement('#___gatsby')

export default function Hero({ data, aboutData, contactData, onPlayerLoad, onPlayerPlay }) {
  const { demourl: demoUrl } = data
  const aboutText = aboutData.node.frontmatter.content;
  const links = contactData.node.frontmatter.links;
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)

  const handleClickAbout = (e) => {
    e && e.preventDefault()
    isContactModalOpen && setIsContactModalOpen(false)
    setIsAboutModalOpen(true)
  }

  const handleClickContact = (e) => {
    e && e.preventDefault()
    isAboutModalOpen && setIsAboutModalOpen(false)
    setIsContactModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsContactModalOpen(false)
    setIsAboutModalOpen(false)
  }

  return (
    <div className="hero">
      {(isContactModalOpen || isAboutModalOpen) &&
        <Modal
          isOpen
          style={{
            content: {
              position: 'relative',
              color: 'white',
              backgroundColor: 'transparent',
              border: 'none',
              maxWidth: '24rem',
              inset: '0px',
              padding: '0 2rem',
              boxSizing: 'border-box'
            },
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.4)',
              animation: '0.15s fade-in',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000
            }
          }}
          onRequestClose={handleCloseModal}
        >
          <div className="hero__modal">
            <div className="hero__modal__title">{isContactModalOpen ? 'Contact' : 'About'}</div>
            {isContactModalOpen &&
              <div className="hero__modal__body hero__modal__body--links">
                {links.map((link, index) =>
                  <a key={`hero-link-${index}`} href={link.link} target="_blank" className="hero__modal__body__link">
                    {link.label}
                  </a>
                )}
              </div>
            }
            {isAboutModalOpen &&
            <div className="hero__modal__body">
              {aboutText}
            </div>
            }
          </div>
        </Modal>
      }
      <div className="hero__heading">
        <div className="hero__heading__title">
          Mark Sparling Music
        </div>
        <div className="hero__heading__links">
          <a onClick={handleClickAbout} href="#" className="hero__heading__links__link">About</a>
          <a onClick={handleClickContact} href="#" className="hero__heading__links__link">Contact</a>
        </div>
      </div>
      <div className="hero__video">
        <DynamicPlayer url={demoUrl} large preInitialize onLoad={onPlayerLoad} onPlay={onPlayerPlay} />
      </div>
    </div>
  )
}
