import React from 'react'
import DynamicPlayer from './common/DynamicPlayer'
import './game.scss'

export default function Game({ data, onPlayerLoad, onPlayerPlay, onPlayerClick }) {
  const { title, year, link, embed, copyright, role, backgroundimage } = data

  return (
    <div className="game">
      <div
        className="game__card"
        style={
          { backgroundImage: `url('${backgroundimage}')` }
        }
      >
        <div className="game__card__player">
          <DynamicPlayer onPlayerClick={() => onPlayerClick(backgroundimage)} url={embed} onLoad={onPlayerLoad} onPlay={onPlayerPlay} />
        </div>
        <div className="game__card__info">
          <a href={link} target="_blank" className="game__card__info__title">
            {title}
          </a>
          <div className="game__card__info__subtitle">
            {year} - {role}
          </div>
          <div className="game__card__info__copyright">
            <i>{copyright}</i>
          </div>
        </div>
      </div>
    </div>
  )
}
